import RocketIcon from "../../resources/images/tfp-ribbon-rocket-icon.svg";
import "./styles/TfpRibbon.css";

function TfpRibbon() {
  return (
    <div className="tfp-ribbon-container">
            <a
                href="https://programmingpathshala.com/tech-fellowship-program"
                className="tfp-ribbon-wrapper"
                target="_self"
            >
                <div className='tfp-announcement-content'>
                  <div className='tfp-announcement-logo-item'>
                    <div className='tfp-rocket-icon-img'>
                      <img src={RocketIcon} />
                    </div>
                    <h3 className='tfp-announcement-logo-item-head'>READY TO LAUNCH YOUR TECH CAREER?</h3>
                  </div>
                  <div className='tfp-announcement-content-text'>
                    <p className="tfp-announcement-content-head">
                      Join Our Tech Fellowship Program Now
                    </p>
                    <p className='tfp-announcement-content-para tfp-announcement-content-para-1'>
                      Unlock Your Future Before It's Gone- Limited Slots!
                    </p>
                    <p className='tfp-announcement-content-para tfp-announcement-content-para-2'>
                      Click here to explore
                    </p>
                  </div>
                  <button className='tfp-ribbon-claim-now-btn'>
                    Apply Now
                  </button>
                </div>
            </a>
        </div>
  )
}

export default TfpRibbon
